/**
 * Dev, based on $env (from gulp)
 */
$env: 'dev' !default;


/**
 * Colors
 * for the naming use http://name-of-color.com/
 * from darker to lighter ?
 */

// Global colors
$black: #000;
$white: #fff;
$transparent: rgba(0, 0, 0, 0);

// Project colors
$saltpan: #edf4ec;
$jagged-ice: #c6e2e9;
$tuna: #37383c;
$alabaster: #f9f9f9;
$silver: #c4c4c4;
$oslo-gray: #898b93;
$wild-sand: #f5f5f5;
$iron: #d7d7d8;
$carnation: #f95367;
$persimmon: #ff5454;
$tundora: #414141;

/**
 * Typography
 */

$base-font-size: 18px !default;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem
);

// The `scalable-text-map` contains options for our `scalable-text` mixin.
// For each entry of `$scalable-text-map`, we define a `min` map and a `max` map.
// In this example this means:
// - For a screen size smaller than 400px, the font-size for the `h1` will be 24px.
// - For a screen size greater than 800px, the font-size for the `h1` will be 36px.
// - For any screen size in between 400px and 800px, the font-size for the `h1` will scale automatically between 24px and 36px.
$scalable-text-map: (
  h1: (
    min: (24px, 'small'),
    max: (36px, 'large')
  ),
  p: (
    min: (13px, 'small'),
    max: (18px, 'large')
  )
);


/*
 * Layout & spacing
 */

$spacing: 20px;


/**
 * RWD
 */
$mq-responsive: true;
$mq-static-breakpoint: 'large';
