.legal {
  padding: 9rem 0;

  p {
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    margin: 4rem 0;
  }
}

.legal table {
  width: 100%;
  max-width: 40rem;
  border-collapse: collapse;

  tr:nth-child(even) {
    background: $alabaster;
  }

  td {
    border: .1rem solid $silver;
    padding: .5rem;
  }

  td[colspan='2'] {
    border: 0;
  }

  tr td:nth-child(2) {
    text-align: right;
  }
}

@include mq(medium) {
  .legal {
    padding: 12rem 0;
  }
}
