.btn {
  display: inline-block;
  width: 15rem;
  height: 4rem;
  border: 0;
  padding: 0;
  background: $persimmon;
  color: $white!important;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .04em;
  border-radius: 10rem;
  -webkit-appearance: none;
  transition: background .3s;
}

.btn:hover {
  background: $carnation;
}
