.footer {
  padding: 6rem 0 1rem 0;
  background: $tuna;
}

.footer .wrapper {
  padding: 0 4rem;
}

.footer__menu {
  display: flex;

  ul {
    @extend %list-nostyle;

    flex-basis: 50%;
  }

  a {
    display: block;
    color: $white;
    font-size: 1.5rem;
    line-height: 2;
    transition: color .2s ease;
  }

  a:hover {
    color: $oslo-gray;
  }
}

.footer__logo {
  width: 15rem;
  height: 8rem;
  margin-top: 10rem;
  font-size: 0;
}

.footer__disclaimer {
  margin: 5rem 0 1rem 0;
  color: rgba($white, .4);
  font-size: 1rem;

  p {
    margin: 0;
  }
}

@include mq(medium) {
  .footer {
    overflow: hidden;
  }

  .footer__top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .footer__logo {
    margin-top: 0;
  }

  .footer__menu {
    flex-basis: 58%;
  }

  .footer__disclaimer {
    float: right;
    width: 58%;
  }
}

@include mq(xlarge) {
  .footer__menu {
    flex-basis: 50%;
  }

  .footer__disclaimer {
    float: right;
    width: 50%;
  }
}
