// Import fonts

@font-face {
  font-family: 'Graphik';
  src: url('./../fonts/thin.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Graphik';
  src: url('./../fonts/regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Graphik';
  src: url('./../fonts/bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Graphik';
  src: url('./../fonts/extrabold.woff') format('woff');
  font-style: normal;
  font-weight: 900;
}

html {
  font-size: calc(1em * .625);
}

body {
  font-family: 'Graphik', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $tuna;
  font-size: 1.6rem;
  line-height: 1.5;
}


h1,
.h1, {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.2;

  @include mq(xlarge) {
    font-size: 4rem;
  }
}

h2,
.h2, {
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 1.25;
}

h3,
.h3, {
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1.25;
}

h4,
.h4, {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.45;
}
