/// Modifier generator
/// @author tm
/// @param {String} $module - Module name, for classnames
/// @param {Map} $map - Modifier name + value OR Modifier name + map(property: value)
/// @param {String} $property [null] - Property (if simple map, with no properties, is used above
/// @content [common rules for the module class]
/// @example scss - Modifier generator (single property)
///   $tags: (
///     'sport': yellow,
///     'media': green
///   );
///   @include modifier-generator('tags', $tags, 'color') {
///     margin: 1em;
///   }
///
///   // .tags,
///   // [class*="tags--"] {
///   //   margin: 1em;
///   // }
///   //
///   // .tags--sport {
///   //   color: yellow;
///   // }
///   //
///   // .tags--media {
///   //   color: green;
///   // }
///
///   $labels: (
///     'news': (
///       'color': blue,
///       'font-size': 12px
///     ),
///     'pro': (
///       'color': pink,
///       'font-size': 16px
///     )
///   );
///   @include modifier-generator('labels', $labels) {
///     margin: 1em;
///   }
///
///   // .labels,
///   // [class*="labels--"] {
///   //   margin: 1em;
///   // }
///   //
///   // .labels--news {
///   //   color: blue;
///   //   font-size: 12px;
///   // }
///   //
///   // .labels--pro {
///   //   color: pink;
///   //   font-size: 16px;
///   // }

@mixin modifier-generator($module, $map, $property: null) {
  .#{$module},
  [class*="#{$module}--"] {
    @content;
  }

  @each $name, $value in $map {
    @if type-of($value) == 'map' {
      .#{$module}--#{$name} {
        @each $prop, $val in $value {
          #{$prop}: $val;
        }
      }
    } @else {
      @if $property == null {
        @error "Invalid or missing property parameter";
      } @else {
        .#{$module}--#{$name} {
          #{$property}: $value;
        }
      }
    }
  }
}


/**
 * RWD
 */

@mixin hi-res($img) {
  @media
      only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min-resolution: 196dpi),
      only screen and (min-resolution: 2dppx) {
    background-image: url($img);
    @content;
  }
}


/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size: 6px, $foreground-color: $black, $background-color: $white) {
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}

// Scalable Text:
// This mixin allows smooth text scaling in responsive design.
// See the `$scalable-text-map` in `_variables.scss` for more informations.
// @param {String} key - The key to select options to use from the `$scalable-text-map`
//
// Usage:
// h1 {
//  @include scalable-text('h1');
// }
@mixin scalable-text($key) {
  $map: map-get($scalable-text-map, $key);

  $min: map-get($map, 'min');
  $max: map-get($map, 'max');

  $font-min-size: nth($min, 1);
  $font-max-size: nth($max, 1);

  $font-min-break: valid-breakpoint(nth($min, 2));
  $font-max-break: valid-breakpoint(nth($max, 2));

  font-size: calc(#{$font-min-size} + (#{strip-unit($font-max-size)} - #{strip-unit($font-min-size)}) * ((100vw - #{$font-min-break}) / (#{strip-unit($font-max-break)} - #{strip-unit($font-min-break)})));

  @include mq($until: $font-min-break) { font-size: $font-min-size; }
  @include mq($from: $font-max-break) { font-size: $font-max-size; }
}


@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin bg-text($background, $alternate) {
  html:not(.backgroundcliptext) & {
    color: #{$alternate};
  }

  html.backgroundcliptext & {
    background: #{$background};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
