.cookie {
  padding: 2rem 0;
  background: $alabaster;
  color: $tundora;
  // display: none;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.cookie .agree {
  margin-left: .4rem;
  color: $carnation;
}

.cookie p {
  margin: 0;
  font-size: 1.1rem;
}
