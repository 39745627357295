.features {
  padding: 6rem 0;
}

.features h2 {
  max-width: 23rem;
  font-weight: 700;
}

.features__carousel {
  @extend %list-nostyle;

  position: relative;
  left: -3rem;
  width: calc(100% + 6rem);
  margin: 5rem 0;

  .flickity-page-dots {
    padding-left: 3rem;
    text-align: left;
  }

  .flickity-page-dots .dot {
    width: .5rem;
    height: .5rem;
    margin: 0 1rem 0 0;
    background: $iron;
    opacity: 1;

    &.is-selected {
      background: $persimmon;
    }
  }
}

.features__slide {
  width: 100%;
  padding: 0 3rem;

  p {
    margin: 0;
    color: $iron;
    font-size: 6.4rem;
    font-weight: 700;
    line-height: 1.1;

    @include mq(tiny, 400px) {
      font-size: 5.5rem;
    }
  }

  span,
  strong {
    display: inline-block;
  }

  strong {
    @include bg-text(linear-gradient(45deg, $persimmon, $carnation), $carnation);
  }
}

.features__goals {
  max-width: 44rem;
  margin-top: 8rem;
  font-size: 1.4rem;

  p {
    color: rgba($tuna, .4);
  }

  ul {
    @extend %list-nostyle;

    column-count: 2;
  }
}

@include mq(medium) {
  .features {
    padding: 15rem 0;
  }

  .features h2 {
    max-width: 44rem;
    margin: 0 8% 0 auto;
    font-size: 2.4rem;
    line-height: 3.6rem;
  }

  .features__carousel {
    position: relative;
    left: 0;
    overflow: hidden;
    width: calc(100% + 3rem);
    height: 20rem;
    white-space: nowrap;
  }

  .features__slide {
    @include get-all-space;

    display: flex;
    align-items: center;

    span,
    strong {
      // height: 20rem;
      // line-height: 20rem;
    }
  }


  .features__goals {
    margin: 5rem 8% 0 auto;
  }
}

@include mq(large) {
  .features {
    margin: 0 3rem;
  }

  .features .wrapper {
    padding-left: 10%;
  }
}


@include mq(xxlarge) {
  .features__goals,
  .features h2 {
    margin-right: 11%;
  }
}
