.press {
  padding: 9rem 0;
  background: $wild-sand;
}

@include mq(large) {
  .press {
    margin: 0 3rem;
    padding: 20rem 0;
  }
}
