.newsletter {
  padding: 9rem 0 6rem 0;
  background: $alabaster;
}

.newsletter .wrapper {
  padding: 0 4rem;
}

.newsletter__text {
  h2 {
    margin: 0 0 2rem;
    font-size: 2.4rem;
  }
}

.newsletter__input p {
  max-width: 35rem;
  margin-bottom: 3rem;
}

@include mq(700px) {
  .newsletter {
    padding: 15rem 0;
  }

  .newsletter .wrapper {
    display: flex;
    align-items: center;
    // padding: 0 5rem;
  }

  .newsletter__text {
    flex-basis: 50%;

    h2 {
      font-size: 3.6rem;
    }
  }

  .newsletter__input {
    flex: 1;
  }
}


@include mq(880px) {
  .newsletter__text {
    margin-left: 8%;
  }
}

@include mq(large) {
  .newsletter {
    padding: 20rem 0;
  }
}


@include mq(xlarge) {
  .newsletter__text {
    flex-basis: 64%;
    margin-left: 0;

    h2 {
      font-size: 6.4rem;
    }
  }
}
