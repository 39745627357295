input[type='text'],
input[type='email'] {
  width: 19rem;
  height: 4.5rem;
  margin-bottom: 3rem;
  border: 0;
  border-bottom: .2rem solid $silver;
  background: transparent;
  font-size: 1.4rem;
  line-height: 4.5rem;
  -webkit-appearance: none;
}
