.screenshots {
  padding: 9rem 0;
}

.screenshots__intro,
.screenshots__intro-column {
  margin-bottom: 4em;

  hr {
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 700;
  }
}

.screenshots__intro-column {
  display: none;
  margin-bottom: 12rem;
}

.screenshots__list {
  @extend %list-nostyle;
}

.screenshots__title {
  margin: 0 0 6rem 0;
}

.screenshot__picture {
  margin-bottom: 3rem;
  padding: 3rem;
  background: linear-gradient($persimmon, $carnation);

  img {
    display: block;
    width: 100%;
    max-width: 20rem;
    margin: 0 auto;
    box-shadow: 0 1rem 4rem rgba($black, .2);
  }
}

.screenshots__list li {
  h3 {
    margin-top: 5rem;
    margin-bottom: .4rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 6rem;
  }
}

@include mq(450px) {
  .screenshot__picture {
    padding: 6rem;

    img {
      max-width: 100%;
    }
  }
}

@include mq(medium) {
  .screenshots__intro {
    display: none;
  }

  .screenshots__intro-column {
    display: block;
  }

  .screenshots__container {
    display: flex;
    justify-content: space-between;
    // max-width: 80rem;
  }

  .screenshots__list {
    flex-basis: 45%;
  }

  .screenshot__picture {
    padding: 2rem;
  }

  .screenshots__title {
    margin-bottom: 9rem;
  }
}

@include mq(medium) {
  .screenshots {
    padding: 3rem 0 12rem 0;
  }

  .screenshots .wrapper {
    padding: 0 5rem;
  }

  .screenshots__list li {
    p {
      margin-bottom: 16rem;
    }
  }

  .screenshot__picture {
    padding: 4rem;
  }
}

@include mq(large) {
  .screenshots .wrapper {
    max-width: 1000px;
    padding-right: 0;
    padding-left: 8%;
  }

  .screenshots__list li {
    h3, p {
      width: 80%;
    }
  }
}

@include mq(xxlarge) {
  .screenshots .wrapper {
    max-width: 1200px;
    padding-right: 0;
    padding-left: 2%;
  }

  .screenshot__picture {
    padding: 6rem;
  }
}
