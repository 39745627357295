.release + .release {
  margin-top: 9rem;
}

.release__author h3 {
  margin: 0 0 3rem;
  font-size: 2.8rem;
  font-weight: 400;
}

.release__logo {
  height: 2.3rem;
  margin-bottom: 4rem;
}

.release__content a {
  display: block;
  margin: 1rem 0;
  color: $persimmon;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: .3s color;

  &:hover {
    color: $carnation;
  }
}

@include mq(medium) {
  .release {
    display: flex;
  }

  .release + .release {
    margin-top: 15rem;
  }

  .release__author,
  .release__content {
    flex-basis: 50%;
  }
}
