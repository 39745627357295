blockquote {
  margin: 0 0 3rem 0;
  font-size: 2.2rem;
  text-indent: -.9rem;

  @include mq(tiny, 400px) {
    font-size: 1.8rem;
  }
}

blockquote::before {
  content: '\201C';
}

blockquote::after {
  content: '\201C';
}


@include mq(medium) {
  blockquote {
    width: 80%;
  }
}
