hr,
.hr {
  width: 11rem;
  height: .2rem;
  margin: 0 0 7rem 0;
  border: 0;
  padding: 0;
  background: $tuna;
  text-align: left;
}
