.badges {
  @extend %list-nostyle;
  display: flex;
  justify-content: space-between;
  max-width: 30rem;
  margin-top: 3rem;

  li {
    flex-basis: 48%;
  }

  li img {
    width: 100%;
  }
}

@include mq(medium) {
  .badges {
    margin-top: 5rem;
  }
}


html.ios {
  .badges .android {
    display: none;
  }
}

html.android {
  .badges .ios {
    display: none;
  }
}
