.hero {

}

.hero__whitelayer {
  @include get-all-space;

  z-index: 100;
  right: auto;
  display: none;
  width: 0%;
  background: $white;
}

.hero__cover {
  position: relative;
  z-index: 20;
  overflow: hidden;
  height: 100vh;
  min-height: 80rem;
}

.hero__cover .wrapper {
  position: relative;
  z-index: 30;
}

.hero__layer {
  @include get-all-space;

  background: linear-gradient($persimmon, $carnation);
}

[class*='hero__phone'] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  pointer-events: none;
}

[class*='phone-wrapper'] {
  @include get-all-space;

  &.animate {
    animation: updown 3s infinite alternate ease-in-out;

    &.phone-wrapper-left {
      animation: updown 2.25s .75s infinite alternate ease-in-out;
    }
  }
}

.hero__phone-left {
  z-index: 10;
  bottom: 17vh;
  width: 60%;
  max-width: 30rem;
  margin-left: -28vw;

  @include mq(tiny, medium) {
    max-width: 26rem;
  }

  @include mq(medium) {
    z-index: 10;
    bottom: 55.7vh;
    width: 70%;
    margin-left: -14.5vw;
  }

  @include mq(xxlarge) {
    width: 30vw;
    max-width: 30rem;
    margin-left: -12vw;

    @media screen and (min-height: 800px) {
      max-width: 40rem;
    }
  }
}

.hero__phone-right {
  bottom: -3rem;
  width: 57%;
  min-width: 22rem;
  max-width: 32rem;
  margin-left: 30vw;

  @include mq(tiny, medium) {
    max-width: 20em;
  }

  @include mq(medium) {
    bottom: 29.6vh;
    width: 68%;

    margin-left: 13.9vw;
  }

  @include mq(xxlarge) {
    width: 30vw;
    margin-left: 14vw;
    max-width: 30rem;

    @media screen and (min-height: 800px) {
      max-width: 40rem;
    }
  }
}


.hero__cover__title {
  max-width: 50rem;
  margin: 0;
  padding-top: 11rem;
  color: $white;
  font-size: 3rem;

  @include mq(tiny, 350px) {
    font-size: 2.6rem;
  }
}

.hero__content {
  margin-top: 6rem;

  hr {
    @media screen and (max-height: 750px) {
      margin-bottom: 4rem;
    }

    @media screen and (max-height: 700px) {
      display: none;
    }
  }

}

.hero__content .badges {
  display: none;
}

.hero__content h1 {
  display: none;
}

@include mq(medium) {
  .hero {
    position: relative;
  }

  .hero__cover {
    width: 50%;
    height: 130vh;
  }

  .hero__cover .badges {
    display: none;
  }

  .hero__content .badges {
    display: flex;
  }

  .hero__cover__title {
    display: none;
  }

  .hero__content {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100vh;
    margin-top: 0;
    padding: 3rem 3rem 0 3rem;

    h1 {
      display: block;
      margin: 0 0 3rem 0;

    }
  }

  .hero__content .wrapper {
    max-width: 50rem;
    margin-left: 0;
  }
}

@include mq(medium, large) {
  .hero__content h1 {
    font-size: 2.6rem;
  }
}

@include mq(large) {
  .hero__content {
    padding-left: 6rem;
  }
}


@include mq(xlarge) {
  .hero__phone-left {
    // max-width: 36rem;
    // margin-left: -9vw;
  }

  .hero__phone-right {
    // max-width: 37rem;
  }
}

@keyframes updown {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(10px);
  }
}
