// //Avoid typekit FOUT
// html {
//   &.js {
//     opacity: 0;
//     transition: opacity .2s ease-in-out;

//     &.wf-active,
//     &.wf-inactive,
//     &.ready {
//       opacity: 1;
//     }
//   }
// }

html {
  opacity: 0;

  &.ready {
    opacity: 1;
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  width: 100%;
  max-width: 135rem;
  margin: 0 auto;
  padding: 0 3rem;
}

main {
  position: relative;
  background: $white;
}
