.header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5rem;

  @include mq(large) {
    @media screen and (min-height: 900px) {
      padding-top: 10rem;
    }
  }
}

@include mq(xlarge) {
  .header.sticky {
    position: fixed;
    background: rgba($white, .9);
    padding: 0;

    .wrapper {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      border-bottom: .1rem solid rgba(55,56,60,.1);
    }

    .logo-black {
      opacity: 1;
    }

    .header__logo {
      width: 15rem;
    }

    .header__menu {
      flex-basis: auto;
    }
  }
}

.header__logo {
  position: relative;
  width: 20rem;
  font-size: 0;

  .logo-black {
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.header__menu {
  display: none;
  flex-basis: 50%;
}

.header__menu ul {
  @extend %list-nostyle;

  display: flex;
}

.header__menu li {
  padding: 0 1.5rem;
}

.header__menu a {
  position: relative;
  font-size: 1.4rem;

  &::after {
    content: '';
    position: absolute;
    bottom: -.7rem;
    left: 0;
    display: block;
    width: 100%;
    height: .2rem;
    background: linear-gradient(45deg, $carnation, $persimmon);
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    opacity: 0;
    transform: translateY(2px) scale(.8);
  }

  &.active::after,
  &:hover::after, {
    transform: translateY(0px) scale(1);
    opacity: 1;
    // width: 100%;
    // opacity: 1;
  }
}

@include mq(xlarge) {
  .header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__menu {
    display: block;

    ul {
      padding-left: 7.5rem;
    }
  }
}
